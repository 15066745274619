import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/style.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { BTUtil } from "@/utils/util"

Vue.prototype.$util = BTUtil;

Vue.config.productionTip = false;

Vue.use(ElementUI, {
  size: 'small' // set element-ui default size
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
