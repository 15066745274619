
import {filterHtml} from "../filters/index";

export class BTUtil {
    // 限制字数
    static ellipsis(value, maxLength) {
        if (!value) return ''
        value = filterHtml(value);
        console.log(value.length)
        if (value.length > maxLength) {
            return value.slice(0, maxLength) + '...'
        }
        return value
    }

    static getYear(value){
        let time = new Date(value);
       return  time.getFullYear()
    }

    static getMonth(value){
        let time = new Date(value);
        return  time.getMonth()+1
    }

    static getDay(value){
        let time = new Date(value);
        return  time.getDay()
    }

}

