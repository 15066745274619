import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    // {
    //     path:'/',
    //     redirect:'/index'
    // },
    {
        path: '/',
        name: 'index',
        component: () => import('../views/IndexView.vue')
    },
    {
        path: '/businessReservation',
        name: 'product',
        component: () => import('../views/BusinessReservation.vue')
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/News.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/About.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
