// 限制字数
export function ellipsis (value, maxLength) {
    if (!value) return ''
    value = filterHtml(value);
    if (value.length > maxLength) {
        return value.slice(0, maxLength) + '...'
    }
    return value
}

/**
 * 隐藏手机号中间四位
 * @param cellValue
 * @returns {*}
 */
export function hidePhoneNum (cellValue) {
    if (Number(cellValue) && String(cellValue).length === 11) {
        var mobile = String(cellValue)
        var reg = /^(\d{3})\d{4}(\d{4})$/
        return mobile.replace(reg, '$1****$2')
    } else {
        return cellValue
    }
}


// 过滤html
export function filterHtml (value) {
     let replace1 = value.replace(/<[^>]*>/g, '');
     let replace2 = replace1.replace(/&nbsp;/ig, "");
     let res = replace2.replace(/&amp;/ig, "&");

     return res;
}




